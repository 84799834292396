<script>
import get from 'lodash/get';
import { mapActions, mapGetters, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';

import { MuiTable } from '@emobg/motion-ui/v1';

import { contentCells } from './invoicesRelatedContentCells';

export default {
  name: 'InvoicesRelatedView',

  components: {
    MuiTable,
  },

  inject: ['$labels'],

  data() {
    return {
      relatedInvoices: [],
    };
  },

  computed: {
    ...mapState(DOMAINS_MODEL.invoicing.invoices, {
      isLoading: state => get(state, 'invoices.STATUS.LOADING'),
    }),

    ...mapGetters(DOMAINS_MODEL.invoicing.invoices, ['invoiceData', 'childInvoicesData']),

    parentInvoiceUuid: ({ invoiceData }) => get(invoiceData, 'linkedInvoice.uuid'),
  },

  async created() {
    this.invoiceUuid = get(this.$route, 'params.invoiceUuid');
    this.contentCells = contentCells;

    await this.getInvoiceByUuid({ invoiceUuid: this.invoiceUuid });

    if (this.parentInvoiceUuid) {
      await this.getInvoiceByUuid({ invoiceUuid: this.parentInvoiceUuid });
    }

    this.relatedInvoices = [this.invoiceData, ...this.childInvoicesData];
  },

  methods: {
    ...mapActions(DOMAINS_MODEL.invoicing.invoices, ['getInvoiceByUuid']),
  },
};
</script>

<template>
  <div class="InvoicesRelatedView">
    <h2>
      {{ $labels.Related.title }}
    </h2>

    <ui-loader
      v-if="isLoading"
      fixed
      data-test-id="loader"
    />

    <ui-card
      nested
      rounded
      class="d-block py-3 mt-2"
    >
      <MuiTable
        :data-set="relatedInvoices"
        :content-cells="contentCells(invoiceUuid)"
        :no-data-label="FALLBACK_MESSAGE.dash"
        :loading="isLoading"
        :page-size="20"
        class="mx-3"
      />
    </ui-card>
  </div>
</template>
