import { sentenceCase } from '@emobg/web-utils';
import { RECORD_TYPES } from '@emobg/motion-ui';
import invoicingRoutes from '@domains/Invoicing/router/InvoicingRouterMap';
import { INVOICING_PERMISSIONS } from '@domains/Invoicing/const/permissions';
import { INVOICE_STATUS } from '@domains/Invoicing/const/INVOICE_STATUS';
import { COLORS, GRAYSCALE } from '@emobg/vue-base';
import { PermissionLink } from '@/components';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

export function contentCells(invoiceUuid) {
  return [
    {
      title: 'Invoice',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 180,
      type: RECORD_TYPES.component,
      component: PermissionLink,
      props: result => ({
        classLink: 'emobg-link-primary emobg-body-2',
        ...(invoiceUuid === result.uuid
          ? {
            to: {
              name: invoicingRoutes.invoices.invoice.details,
            },
          }
          : {
            target: '_blank',
            href: `/invoicing/invoices/${result.uuid}/summary/details`,
          }
        ),
        linkPermissions: [INVOICING_PERMISSIONS.viewInvoicingInvoices],
        text: result.reference,
      }),
    },
    {
      property: 'status',
      title: 'Status',
      displayPriority: 1,
      columnRatio: 1,
      minWidth: 130,
      type: RECORD_TYPES.component,
      component: BadgeComponent,
      props: ({ status }) => ({
        text: sentenceCase(status),
        color: status === INVOICE_STATUS.open ? COLORS.warning : GRAYSCALE.ground,
      }),
    },
    {
      property: 'closedDateStr',
      title: 'Date of issue',
      displayPriority: 1,
      minWidth: 180,
      columnRatio: 1,
    },
    {
      property: 'totalAmount.valueStr',
      title: 'Total amount',
      displayPriority: 1,
      minWidth: 140,
      displayOrder: 1,
      columnRatio: 1,
    },
  ];
}
