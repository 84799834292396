var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "InvoicesRelatedView" },
    [
      _c("h2", [_vm._v(" " + _vm._s(_vm.$labels.Related.title) + " ")]),
      _vm.isLoading
        ? _c("ui-loader", { attrs: { fixed: "", "data-test-id": "loader" } })
        : _vm._e(),
      _c(
        "ui-card",
        {
          staticClass: "d-block py-3 mt-2",
          attrs: { nested: "", rounded: "" },
        },
        [
          _c("MuiTable", {
            staticClass: "mx-3",
            attrs: {
              "data-set": _vm.relatedInvoices,
              "content-cells": _vm.contentCells(_vm.invoiceUuid),
              "no-data-label": _vm.FALLBACK_MESSAGE.dash,
              loading: _vm.isLoading,
              "page-size": 20,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }